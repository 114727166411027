import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import cacheManifest from '../../../../i18n-cache-manifest.json';

export class MultiTranslateHttpLoader implements TranslateLoader {
  private cacheKeys: { [key: string]: any } = cacheManifest;
  constructor(
    private http: HttpClient,
    public resources: { prefix: string; suffix?: string }[] = [
      {
        prefix: 'src/assets/i18n/',
        suffix: '.json'
      }
    ]
  ) {}

  /**
   * Gets the translations from the server
   * @param lang
   * @returns {any}
   */
  public getTranslation(lang: string): any {
    return forkJoin(
      this.resources.map((config) => {
        const url = `${config.prefix}${lang}${config.suffix || '.json'}`;
        const cacheKey = this.cacheKeys[url] || '';
        return this.http.get(`${url}${cacheKey && '?v=' + cacheKey}`);
      })
    ).pipe(
      map((response) => {
        return response.reduce((a, b) => {
          return Object.assign(a, b);
        });
      })
    );
  }
}
